.file-box[data-v-3c3bf755] {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.file-box .file-item[data-v-3c3bf755] {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 120px;
}
.file-box .file-item .file-img[data-v-3c3bf755] {
  display: block;
  width: 100px;
  height: 120px;
  -o-object-fit: cover;
     object-fit: cover;
}
.file-box .file-item .iconfont[data-v-3c3bf755] {
  font-size: 60px;
}
.file-box .file-item p[data-v-3c3bf755] {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 20px;
  padding: 0 5px;
  text-align: center;
  background-color: #c8c9cc;
}
.file-box .file-item .el-checkbox[data-v-3c3bf755] {
  position: absolute;
  top: 5px;
  left: 5px;
  height: auto;
}
